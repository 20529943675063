<template><v-app><Header /><v-main><v-container fluid>
<v-row dense><v-col cols="12"><v-card>
    <v-card-title class="text-h5 green text--info white--text">{{ $t('Borrowing') }}</v-card-title>
    <v-card-subtitle class="green white--text">{{ $t('Berikut data peminjaman') }}</v-card-subtitle>
    <v-card-text class="text--primary mt-2">
        <v-row dense v-for="(datat, index) in datatables" :key="datat.id" :id="index"><v-col cols="12"><v-card><v-card-text>
            <v-row class="blue white--text font-weight-bold" >
                <v-col cols="6" md="3">{{ $t('Jatuh tempo') }}</v-col>
                <v-col cols="6" md="3">{{ datat.jatuh_tempo }}</v-col>
                <v-col cols="6" md="3">{{ $t('Return') }}</v-col>
                <v-col cols="6" md="3">{{ datat.tgl_kembali }}</v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="3">{{ $t('No. Transaksi') }}</v-col>
                <v-col cols="6" md="3">{{ datat.no_transaksi }}</v-col>
                <v-col cols="6" md="3">{{ $t('No. Barcode') }}</v-col>
                <v-col cols="6" md="3">{{ datat.no_barcode }}</v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="3">{{ $t('Title') }}</v-col>
                <v-col cols="6" md="3">{{ datat.judul }}</v-col>
                <v-col cols="6" md="3">{{ $t('Publisher') }}</v-col>
                <v-col cols="6" md="3">{{ datat.penerbitan }}</v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="3">{{ $t('Tgl. pinjaman') }}</v-col>
                <v-col cols="6" md="3">{{ datat.tgl_pinjam }}</v-col>
            </v-row>
            
            <v-row v-show="datat.pelanggarans.length > 0"><v-col cols="12"><v-card>
            <v-card-title class="text-h5 red text--info white--text">{{ $t('Pelanggaran') }}</v-card-title>
            <v-card-text>
            <v-card class="mt-2" dense v-for="(pelanggaran1, index12) in datat.pelanggarans" :key="pelanggaran1.id" :id="index12"><v-card-text>
                <v-row>
                    <v-col cols="6" md="3">{{ $t('Denda') }}</v-col>
                    <v-col cols="6" md="3">{{ pelanggaran1.nama_denda }}</v-col>
                    <v-col cols="6" md="3">{{ $t('Pelanggaran') }}</v-col>
                    <v-col cols="6" md="3">{{ pelanggaran1.jenis_pelanggaran }}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">{{ $t('Suspend') }}</v-col>
                    <v-col cols="6" md="3">{{ pelanggaran1.jumlah_suspend }}</v-col>
                    <v-col cols="6" md="3">{{ $t('Biaya denda') }}</v-col>
                    <v-col cols="6" md="3">{{ pelanggaran1.jumlah_denda }}</v-col>
                </v-row>
            </v-card-text></v-card>
            </v-card-text></v-card></v-col></v-row>
            
            
        </v-card-text></v-card></v-col></v-row>
    </v-card-text>
</v-card></v-col></v-row>
</v-container></v-main><Footer /></v-app></template>
<script>
import Header from './layout/Header';
import Footer from './layout/Footer';

  export default {
    components: { Header, Footer },
    data: () => ({
        ajaxProcess:false,
        datatables:[],
    }),
    methods: {
        refreshTable() {
            var vue = this;
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };
                        
            vue.ajaxProcess = true;
            fetch(process.env.VUE_APP_WEB_API + 'api/pinjaman-table', requestOptions)
            .then(async response => {
                vue.datatables = await response.json();
                
                var lengthData = vue.datatables.length;
                if (lengthData > 0) {
                    for (let i = 0; i < lengthData; i++) {
                        var dataUsed = vue.datatables[i];
                        dataUsed.pelanggarans = JSON.parse(dataUsed.pelanggarans);
                        var pelanggaranLength = dataUsed.pelanggarans.length;
                        if (pelanggaranLength > 0) {
                            for (let x = 0; x < pelanggaranLength; x++) {
                                dataUsed.pelanggarans[x] = JSON.parse(dataUsed.pelanggarans[x]);
                            }
                        }
                    }
                }
                
                vue.ajaxProcess = false;
            })
            .catch(() => {
                window.location.replace("/logout");
            });
        }
    },
    mounted() {
        this.refreshTable();   
    }
  }
</script>
